// Override Bootstraps Variables Here
// Reference node_modules/bootstrap/scss/_variables.scss for a full list of variables
// https://getbootstrap.com/docs/4.3/getting-started/theming/#variable-defaults

$navbar-light-active-color: black;
$primary: #c96dea;
$secondary: #0091fe;

.custom-beige {
  background-color: #deb887 !important; /* Hex für beige */
  color: #deb887; /* Optionale Textfarbe */
  border-color: #deb887; /* Optional, falls eine passende Rahmenfarbe nötig ist */
}
