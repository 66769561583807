:root {
  --im-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --im-color: #fff;
  --im-bg: #0b1016;
  --im-link-color: #5fb3fb;
  --im-link-hover-color: rgba(95, 178, 251, 0.682);
  --im-btn-color: #fff;
  --im-btn-bg: rgba(0, 102, 219, 0.84);
  --im-btn-hover-bg: rgba(9, 80, 161, 0.89);
  --im-btn-active-box-shadow: 0 0 0 0.25em rgba(24, 104, 250, 0.24);
  --im-btn-s-color: var(--im-bg);
  --im-btn-s-bg: rgba(225, 239, 255, 0.8);
  --im-btn-s-hover-bg: rgba(225, 239, 255, 0.95);
  --im-btn-s-active-bg: rgba(225, 239, 255, 0.6);
  --im-overlay-bg: linear-gradient(14deg, #1e3861, rgba(206, 220, 233, 0.118));
}
div[data-service],
div[data-service] .cll,
div[data-service] .cll a,
div[data-service] .cll button {
  all: initial;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  visibility: unset;
}
div[data-service] .cll :after,
div[data-service] .cll iframe,
div[data-service] .cll span,
div[data-service]:before {
  all: unset;
  box-sizing: border-box;
}
div[data-service] .cll a,
div[data-service] .cll button,
div[data-service] .cll input {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  display: revert;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
  margin: 0;
  outline: revert;
  overflow: hidden;
}
div[data-service] {
  background-color: #0b1016;
  background-color: var(--im-bg);
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-family: var(--im-font-family);
  font-size: 16px;
  font-weight: 400;
  max-width: 100%;
  min-height: 9.375rem;
  min-width: 18.75rem;
  overflow: hidden;
  position: relative;
}
div[data-service]:before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
div[data-service] .cll .c-ld {
  bottom: 2em;
  opacity: 0;
  right: 2.5em;
  transform: translateY(1.4em);
  visibility: hidden;
}
div[data-service] .cll .c-ld,
div[data-service] .cll .c-ld:after {
  border-radius: 100%;
  height: 1.25em;
  position: absolute;
  width: 1.25em;
  z-index: 1;
}
div[data-service] .cll .c-ld:after {
  animation: spin 1s linear infinite;
  border: 0.25em solid #fff;
  border-top-color: transparent;
  content: "";
}
div[data-service].c-h-n .cll .c-ld {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
div[data-service].c-h-b .cll .c-ld {
  opacity: 0;
  transform: translateY(0);
  visibility: hidden;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
div[data-autoscale] {
  height: auto;
  width: 100%;
}
div[data-service] .cll .c-nt {
  bottom: 0;
  color: #fff;
  color: var(--im-color);
  height: 100%;
  left: 0;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
div[data-service] .cll .c-bg {
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
}
div[data-service] .cll .c-bg:before {
  background: linear-gradient(14deg, #1e3861, rgba(206, 220, 233, 0.118));
  background: var(--im-overlay-bg);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
div[data-service] .cll .c-bg-i {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}
div[data-service] .cll .c-bg-i.loaded {
  opacity: 1;
}
div[data-service] .cll .c-tl {
  display: block;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.6em;
  text-align: center;
}
div[data-service].c-h-n .cll .c-bg {
  opacity: 1;
  transform: scale(1);
}
div[data-service] .cll .c-n-c {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
}
div[data-service] .cll .c-n-t {
  display: block;
  font-size: 0.87em;
  line-height: 1.4em;
  margin: 0 auto;
  max-width: 32em;
  position: relative;
  z-index: 1;
}
div[data-service] .cll .c-n-a {
  margin-top: 1.25em;
}
div[data-service] .cll .c-n-a,
div[data-service] .cll .c-n-t {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
div[data-service] .cll .c-n-a button + button {
  margin-left: 0.8em;
}
div[data-service] .cll .c-t-cn {
  padding: 0 0.75em;
  z-index: 1;
}
div[data-service] .cll .c-l-b,
div[data-service] .cll .c-la-b {
  align-items: center;
  align-self: stretch;
  background: rgba(0, 102, 219, 0.84);
  background: var(--im-btn-bg);
  border-radius: 0.3125em;
  box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.19);
  color: #fff;
  color: var(--im-btn-color);
  display: flex;
  font-size: 0.77em;
  font-weight: 600;
  justify-content: space-evenly;
  padding: 0.975em 1.1em;
  position: relative;
}
div[data-service] .cll .c-l-b:before {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom: 0.6em solid transparent;
  border-left: 1em solid #fff;
  border-left: 1em solid var(--im-btn-color);
  border-top: 0.6em solid transparent;
  content: "";
  display: block;
  margin-right: 0.75em;
}
div[data-service] .cll .c-la-b {
  background: rgba(225, 239, 255, 0.8);
  background: var(--im-btn-s-bg);
  color: #0b1016;
  color: var(--im-btn-s-color);
}
div[data-service] .cll .c-la-b:hover {
  background: rgba(225, 239, 255, 0.95);
  background: var(--im-btn-s-hover-bg);
}
div[data-service] .cll .c-l-b:hover {
  background: rgba(9, 80, 161, 0.89);
  background: var(--im-btn-hover-bg);
}
div[data-service] .cll .c-la-b:active {
  background: rgba(225, 239, 255, 0.6);
  background: var(--im-btn-s-active-bg);
  transition: none !important;
}
div[data-service] .cll .c-l-b:active {
  box-shadow: 0 0 0 0.25em rgba(24, 104, 250, 0.24);
  box-shadow: var(--im-btn-active-box-shadow);
  transition: none !important;
}
div[data-service].c-h-n .cll .c-t-cn {
  opacity: 0;
  transform: translateY(-0.7em);
  transition-duration: 0.2s;
  visibility: hidden;
}
div[data-service] .cll iframe {
  background: #fff;
  border: none;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  max-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}
div[data-service].c-h-b .cll iframe {
  display: block !important;
  height: 100% !important;
  opacity: 1;
  visibility: visible;
}
div[data-service] .cll .c-n-t a {
  border-bottom: 1px solid #5fb3fb;
  border-bottom: 1px solid var(--im-link-color);
  color: #5fb3fb;
  color: var(--im-link-color);
  text-decoration: none;
}
div[data-service] .cll .c-n-t a:hover {
  --im-link-color: var(--im-link-hover-color);
}
div[data-service][data-ratio="1:1"]:before {
  padding-top: 100%;
}
div[data-service][data-ratio="2:1"]:before {
  padding-top: 50%;
}
div[data-service][data-ratio="3:2"]:before {
  padding-top: 66.666666%;
}
div[data-service][data-ratio="5:2"]:before {
  padding-top: 40%;
}
div[data-service][data-ratio="4:3"]:before {
  padding-top: 75%;
}
div[data-service][data-ratio="16:9"]:before {
  padding-top: 56.25%;
}
div[data-service][data-ratio="16:10"]:before {
  padding-top: 62.5%;
}
div[data-service][data-ratio="20:9"]:before {
  padding-top: 45%;
}
div[data-service][data-ratio="21:9"]:before {
  padding-top: 42.857142%;
}
div[data-service][data-ratio="9:16"]:before {
  padding-top: 177.777777%;
}
div[data-service][data-ratio="9:20"]:before {
  padding-top: 222.222222%;
}
div[data-widget]:before {
  display: none;
}
div[data-widget].c-h-b .cll .c-ld {
  opacity: 0;
  visibility: hidden;
}
div[data-widget] .cll + *,
div[data-widget] .cll > :last-child {
  height: 100% !important;
  margin: 0 !important;
}
div[data-service] [data-placeholder] {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
div.c-an [data-placeholder] {
  animation: none;
}
div[data-service] [data-placeholder][data-visible] {
  align-items: center;
  animation: fadeIn 0.2s forwards;
  animation-delay: 0.4s;
  color: #fff;
  color: var(--im-color);
  display: flex;
  justify-content: center;
}
div[data-service].show-ph [data-placeholder] {
  opacity: 1;
  visibility: visible;
}
div[data-service].show-ph .cll .c-ld {
  opacity: 0;
  visibility: hidden;
}
div.c-an .cll .c-bg,
div.c-an .cll .c-bg:before,
div.c-an .cll .c-ld,
div.c-an .cll .c-n-t,
div.c-an .cll .c-t-cn {
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.4s ease;
}
div.c-an .cll .c-bg-i {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
div.c-an[data-widget] {
  transition: opacity 0.3s ease, background-color 0.3s ease;
}
div.c-an .cll .c-l-b,
div.c-an .cll .c-la-b {
  transition: opacity 0.3s ease, transform 0.3s cubic-bezier(0.25, 1, 0.5, 1),
    visibility 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
div.c-an .cll .c-n-t a {
  transition: color 0.2s ease, border-color 0.2s ease;
}
div.c-an .cll iframe,
div.c-an [data-placeholder] {
  transition: opacity 0.3s ease, visibility 0.3s ease;
  transition-delay: 0.1s;
}
